import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../assets/style/navbar.css'; 
import logo from "../assets/images/logo.png";
import '@fortawesome/fontawesome-free/css/all.min.css';
// import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate } from 'react-router-dom';

const Navba = () => {

const navigate = useNavigate();
  
const goToAboutPage = () => {
    navigate('/login');
  };

 
    return (
        <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <div className="row logo-bg mb-1 col-sm-4">
            <a className="navbar-brand" href="#">
              <img src= {logo} alt="Logo" width="40%" />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mt-1 mb-2 col-sm-6">
            <li className="nav-item">
                <a className="nav-link" href="#">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Series
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Movies
                </a>
              </li>
              <NavDropdown
                  className='nav-dropdown ms-3'
                  id="nav-dropdown-dark-example"
                  title="Category"
                  menuVariant="dark"
                >
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
            </ul>
            <div className="search-container mt-2">
              <input
                type="text"
                name="search"
                placeholder="Search..."
                className="search-input text-light"
              />
              <a href="#" className="search-btn">
                <i className="fas fa-search"></i>
              </a>
            </div>
            <a onClick={goToAboutPage} href="#" className="account-icon">
            <i class="fa-solid fa-user"></i>
              </a>
          </div>
        </div>
      </nav>
  )
}

export default Navba