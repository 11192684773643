import React from 'react';
import '../assets/bootstrap-5.3.3-dist/css/bootstrap.css'; // Adjust the path as needed
import '../assets/style/signup.css'; // Adjust the path as needed
import logo from "../assets/images/logo.png";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useNavigate } from 'react-router-dom';
// import 'font-awesome/css/font-awesome.min.css';

function Signup() {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted');
  };

    const navigate = useNavigate();
  
    const goToAboutPage = () => {
      navigate('/login');
    };

  return (
    <>
      <div className="container d-flex align-items-center justify-content-center bg-img py-1" style={{ minHeight: '100vh' }}>
        <div className="row card-container align-items-center justify-content-center  me-2">
          <div className="col-md-12 col-lg-12">
            <img src={logo} alt="Logo" className="img-fluid align-items-center logo mb-3" style={{width: 200}}/>
            <br />
            <div className="card shadow-lg  card-custom">
              <div className="card-body text-center">
                <h2 className="fw-bold mb-4">Sign up now</h2>
                <form id="signup" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <div className="form-outline">
                      <input type="text" id="form3Example1" className="form-control" required />
                      <label className="form-label" htmlFor="form3Example1">First name</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-outline">
                      <input type="text" id="form3Example2" className="form-control" required />
                      <label className="form-label" htmlFor="form3Example2">Last name</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-outline">
                      <input type="email" id="form3Example3" className="form-control" required />
                      <label className="form-label" htmlFor="form3Example3">Email address</label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="form-outline">
                      <input type="password" id="form3Example4" className="form-control" required />
                      <label className="form-label" htmlFor="form3Example4">Password</label>
                    </div>
                  </div>
                  <div className="form-check d-flex justify-content-center mb-3">
                    <label className="form-check-label form-label" htmlFor="form2Example33">Subscribe to our newsletter</label>
                    <input className="form-check-input mt-3 me-2" type="checkbox" id="form2Example33" defaultChecked />
                  </div>
                 
                  <button onClick={goToAboutPage} type="submit" className="btn submit_btn btn-dark btn-block mb-3">Sign up</button>

                  <p className="mb-3 form-label">or sign up with:</p>
                  <div className="d-flex justify-content-center">
                    <button type="button" className="btn text-white btn-link btn-floating mx-1"><i className="fab fa-facebook-f"></i></button>
                    <button type="button" className="btn text-white btn-link btn-floating mx-1"><i className="fab fa-google"></i></button>
                    <button type="button" className="btn text-white btn-link btn-floating mx-1"><i className="fab fa-twitter"></i></button>
                    <button type="button" className="btn text-white btn-link btn-floating mx-1"><i className="fab fa-github"></i></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
