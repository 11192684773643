import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../assets/style/mid-card.css'; // Import the CSS file for styling

function MidCard({ image, title, text }) {
  return (
    <Row lg={1} md={4} className="g-2">
      {Array.from({ length: 1 }).map((_, idx) => (
        <Col key={idx}>
          <Card className="midcard">
            <div className="image-container">
              <Card.Img variant="top" src={image} className="card-img" />
              <div className="overlay">
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{text}</Card.Text>
                </Card.Body>
              </div>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default MidCard;
