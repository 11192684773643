import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

const VideoCard = ({ videoSrc, title, onPlay }) => {
  return (
    <div className="video-card-container container">
      <div className="card " style={{ position: 'relative', width: '12rem' }}>
        <video 
          src={videoSrc} 
          className="card-video" 
          alt={title} 
          controls
          style={{ width: '200%', height: '200%' }} 
        />
        <div className="card-body" style={{ position: 'absolute', bottom: 0, left: 0, right: 0, textAlign: 'center' }}>
          <h5 className="card-title" style={{ margin: 0 }}>{title}</h5>
        </div>
        <button
          className="play-btn"
          onClick={onPlay}
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'transparent', border: 'none' }}
        >
          <FontAwesomeIcon icon={faPlayCircle} size="3x" />
        </button>
      </div>
    </div>
  );
};

export default VideoCard;
