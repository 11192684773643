import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import '../assets/style/slider-card.css';

const SliderCard = ({ image, title, onPlay }) => {
  return (
    <div className="card">
      <img 
        src={image} 
        className="slider-card" 
        alt={title}
         
      />
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
      </div>
      <button
        className="play-btn"
        onClick={onPlay}
      >
        <FontAwesomeIcon icon={faPlayCircle} size="3x" />
      </button>
    </div>
  );
};

export default SliderCard;
