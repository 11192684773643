import React, { useState } from 'react';
import '../assets/bootstrap-5.3.3-dist/css/bootstrap.css';
import '../assets/style/login.css';
import bgimg from '../assets/images/bg-img.jpg';
import { useNavigate } from 'react-router-dom';
// import 'font-awesome/css/font-awesome.min.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert('Please fill in all fields');
      return;
    }
    // Perform your submission logic here, e.g., API call or further processing
    console.log('Email:', email);
    console.log('Password:', password);
    // Reset form fields if needed
    setEmail('');
    setPassword('');
  };

  const navigate = useNavigate();
  
  const goToAboutPage = () => {
    navigate('/');
  };

  return (
    <div>
      <img className="bg-img" src={bgimg} alt="bg-img" style={{ width: '100%', height: '100%' }} />
      <div className="container-fluid d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <section className="login-box">
          <h2 className="text-white mb-4">Sign in</h2>
          <form id="loginForm" onSubmit={handleSubmit} className="mb-4">
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating mb-3">
              <input
                type="password"
                className="form-control"
                id="floatingPassword"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="floatingPassword">Password</label>
            </div>
            <div className="form-check mb-3">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" />
              <label className="form-check-label text-white" htmlFor="exampleCheck1">
                Remember me
              </label>
            </div>
            <button onClick={goToAboutPage} type="submit" className="btn btn-danger" style={{ width: '100%' }}>
              Sign in
            </button>
            <p className="small-text text-white mt-3">
              New to Streamy?{' '}
              <a className="signup-link" href="#">
                <b>Sign up now</b>
              </a>
            </p>
          </form>
        </section>
      </div>
    </div>
  );
}

export default Login;
