import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

// Styles
import '../assets/style/home.css';

// Images
import banner_1 from '../assets/images/landing_page_img/banner_img_1.jpg';
import banner_2 from '../assets/images/landing_page_img/banner_img_2.jpg';
import banner_3 from '../assets/images/landing_page_img/banner_img_3.jpg';
import banner_4 from '../assets/images/landing_page_img/banner_img_4.jpg';
import midimg1 from '../assets/images/landing_page_img/banner_img_3.jpg';
import midimg2 from '../assets/images/landing_page_img/banner_img_4.jpg';
import bigimg from '../assets/images/landing_page_img/banner_img_2.jpg';
import card_img_1 from '../assets/images/landing_page_img/card_with_text/CWD1.jpg';
import card_img_2 from '../assets/images/landing_page_img/card_with_text/CWD2.jpg';
import card_img_3 from '../assets/images/landing_page_img/card_with_text/CWD3.jpg';
import card_img_4 from '../assets/images/landing_page_img/card_with_text/CWD4.jpg';
import video1 from '../assets/videos/ocr_gif.mp4';
import video2 from '../assets/videos/speech_gif.mp4';



// Components
import Navba from '../components/Navbar';
import Navbar from '../components/Navbar';
import SliderCard from '../components/slider-cards';
import VideoCard from '../components/video-card';
import MidCard from '../components/mid-card';
import Footer from '../components/Footer';


const Homepage = () => {
  const handlePlay = () => {
    console.log('Play button clicked');
  };

  return (
    <>
    <div className="bg-color">
      <Navbar />
      <Carousel>
        <Carousel.Item interval={1500}>
          <img src={banner_1} className="d-block w-100" alt="First slide" />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img src={banner_2} className="d-block w-100" alt="Second slide" />
          <Carousel.Caption>
            <h3 >Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img src={banner_3} className="d-block w-100" alt="Third slide" />
          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          <img src={banner_4} className="d-block w-100" alt="Fourth slide" />
          <Carousel.Caption>
            <h3>Fourth slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <br />
      <h2 className='text-danger text-align-center'><a className="nav-link" href="#">
              Series
            </a></h2>
      <br />
      <div className="container-fluid justify-content">
        <div className="row card-space">
          <div className="col-md-5">
            <VideoCard className="card" videoSrc={video1} onPlay={handlePlay} />
          </div>
          <div className="col-md-6">
            <VideoCard  videoSrc={video2} onPlay={handlePlay} />
          </div>
        </div>
      </div>
        <br />
        <h2 className='text-danger justify-content-center'>Latest Shows</h2>
        <br />
        <Carousel>
        <Carousel.Item interval={1500}>
          <div className="container">
            <div className="row card-space">
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_1} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_2} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_3} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_4} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_1} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_3} title="Sample Video 1" onPlay={handlePlay} />
          </div>
        </div>
      </div>
        </Carousel.Item>
        <Carousel.Item interval={1500}>
        <div className="container">
            <div className="row card-space">
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_1} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_3} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_4} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_2} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_1} title="Sample Video 1" onPlay={handlePlay} />
          </div>
              <div className="col-md-2 d-flex">
            <SliderCard className="card" image={card_img_3} title="Sample Video 1" onPlay={handlePlay} />
          </div>
        </div>
      </div>
    </Carousel.Item>
        </Carousel>
        <br />
        <h2 className='text-danger'>More Shows</h2>
        <br />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MidCard image={bigimg} title={"Sample text 1"}/>
            </div>
          </div>
        </div>
        <br />
        <h2 className='text-danger'>Your Favorite Movie</h2>
        <br />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <MidCard image={midimg1} title={"Sample text 1"}/>
            </div>
            <div className="col-md-6">
              <MidCard image={midimg2} title={"Sample text 1"}/>
            </div>
          </div>
        </div>
        <br />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <MidCard image={midimg1} title={"Sample text 1"}/>
            </div>
            <div className="col-md-6">
              <MidCard image={midimg2} title={"Sample text 1"}/>
            </div>
          </div>
        </div>
        <br />
        
        <br />
        <Footer/>
        </div>
    </>
  );
};

export default Homepage;
